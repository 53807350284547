import { render, staticRenderFns } from "./flightAppv_create.vue?vue&type=template&id=e141ca1c&scoped=true"
import script from "./flightAppv_create.vue?vue&type=script&lang=js"
export * from "./flightAppv_create.vue?vue&type=script&lang=js"
import style0 from "./flightAppv_create.vue?vue&type=style&index=0&id=e141ca1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e141ca1c",
  null
  
)

export default component.exports